import {
  SET_BULK_TRANSACTIONS,
  RESET_BULK_TRANSACTIONS,
  SET_BULK_TRANSACTIONS_STATUS,
} from '@redux/actions/actionTypes'

const initialState = {
  isSuccessful: false,
  transactions: [],
}

const bulkTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BULK_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      }
    case RESET_BULK_TRANSACTIONS:
      return {
        ...state,
        isSuccessful: false,
        transactions: [],
      }
    case SET_BULK_TRANSACTIONS_STATUS:
      return {
        ...state,
        isSuccessful: action.payload,
      }
    default:
      return state
  }
}

export default bulkTransactionReducer
