import { axiosClient } from '@services/clients'

export async function getUserTransactions(
  playerId,
  dateStart,
  dateEnd,
  wallet,
  transactionType,
  itemsCount,
  page,
  search,
) {
  const { data } = await axiosClient(
    `/transactions/player/${playerId}?start=${dateStart}&end=${dateEnd}&wallet=${wallet}&type=${transactionType}&limit=${itemsCount}&page=${page}&text=${search}`,
  )
  return data
}

export async function getTransactions(
  dateStart,
  dateEnd,
  currency,
  transactionType,
  showPage,
  page,
) {
  const { data } = await axiosClient(
    `/transactions?start=${dateStart}&end=${dateEnd}&currency=${currency}&type=${transactionType}&limit=${showPage}&page=${page}`,
  )
  return data
}

export async function getTransactionsTypes() {
  const {
    data: { result },
  } = await axiosClient('/transactions/types')

  return result
}

export async function getTopPlayers(date, players) {
  const {
    data: { result },
  } = await axiosClient(`/transactions/top/?limit=${players}&days=${date}`)
  return result
}

export async function postBonusTransaction(data) {
  const {
    data: { result },
  } = await axiosClient.post('/transactions', data)
  return {
    ...result,
    data,
  }
}

export async function postBulkManualTransactions(data) {
  const {
    data: { result },
  } = await axiosClient.post('/bulk-transactions', data)
  return result
}
