import {
  GENERIC_ERROR_NOTIFICATION,
  BONUS_CREATION_SUCCESS_MESSAGE,
  BONUS_CREATION_FAILURE_MESSAGE,
  BONUS_EDIT_SUCCESS_MESSAGE,
  BONUS_EDIT_FAILURE_MESSAGE,
  TRANSACTION_SUCCESS_AMOUNT,
  TRANSACTION_SUCCESS_BONUS,
  TRANSACTION_FAILURE,
  CAMPAIGN_CREATION_SUCCESS_MESSAGE,
  CAMPAIGN_CREATION_FAILURE_MESSAGE,
  CAMPAIGN_ENABLE_SUCCESS_MESSAGE,
  CAMPAIGN_ENABLE_FAILURE_MESSAGE,
  DELETE_LIMIT_MESSAGE,
  DELETE_LIMIT_MESSAGE_FAILURE,
  CREATE_LIMIT_MESSAGE,
  CREATE_LIMIT_MESSAGE_FAILURE,
  UPDATE_LIMIT_MESSAGE,
  UPDATE_LIMIT_MESSAGE_FAILURE,
  DETAILS_FAILURE_MESSAGE,
  DETAILS_SUCCESS_MESSAGE,
  NOTES_FAILURE_MESSAGE,
  NOTES_SUCCESS_MESSAGE,
  NOTES_DELETE_FAILURE_MESSAGE,
  NOTES_EDIT_SUCCESS_MESSAGE,
  NOTES_EDIT_FAILURE_MESSAGE,
  NOTES_DELETE_SUCCESS_MESSAGE,
  STATUS_FAILURE_MESSAGE,
  STATUS_SUCCESS_MESSAGE,
  ATTACHMENTS_EDIT_FAILURE_MESSAGE,
  ATTACHMENTS_EDIT_SUCCESS_MESSAGE,
  E_KYC_SUCCESS,
  E_KYC_FAIL,
  SAVE_LIFETIME_DEPOSIT_LIMIT_SUCCESS,
  SAVE_LIFETIME_DEPOSIT_LIMIT_FAILURE,
  SAVE_AFFORDABILITY_DEPOSIT_LIMIT_SUCCESS,
  SAVE_AFFORDABILITY_DEPOSIT_LIMIT_FAILURE,
  CUSTOM_SUCCESS_NOTIFICATION,
  CUSTOM_ERROR_NOTIFICATION,
} from './notifications.constans'

export {
  GENERIC_ERROR_NOTIFICATION,
  BONUS_CREATION_SUCCESS_MESSAGE,
  BONUS_CREATION_FAILURE_MESSAGE,
  BONUS_EDIT_SUCCESS_MESSAGE,
  BONUS_EDIT_FAILURE_MESSAGE,
  TRANSACTION_SUCCESS_AMOUNT,
  TRANSACTION_SUCCESS_BONUS,
  TRANSACTION_FAILURE,
  CAMPAIGN_CREATION_SUCCESS_MESSAGE,
  CAMPAIGN_CREATION_FAILURE_MESSAGE,
  CAMPAIGN_ENABLE_SUCCESS_MESSAGE,
  CAMPAIGN_ENABLE_FAILURE_MESSAGE,
  DELETE_LIMIT_MESSAGE,
  DELETE_LIMIT_MESSAGE_FAILURE,
  CREATE_LIMIT_MESSAGE,
  CREATE_LIMIT_MESSAGE_FAILURE,
  UPDATE_LIMIT_MESSAGE,
  UPDATE_LIMIT_MESSAGE_FAILURE,
  DETAILS_FAILURE_MESSAGE,
  DETAILS_SUCCESS_MESSAGE,
  NOTES_FAILURE_MESSAGE,
  NOTES_SUCCESS_MESSAGE,
  NOTES_DELETE_FAILURE_MESSAGE,
  NOTES_EDIT_SUCCESS_MESSAGE,
  NOTES_EDIT_FAILURE_MESSAGE,
  NOTES_DELETE_SUCCESS_MESSAGE,
  STATUS_FAILURE_MESSAGE,
  STATUS_SUCCESS_MESSAGE,
  ATTACHMENTS_EDIT_FAILURE_MESSAGE,
  ATTACHMENTS_EDIT_SUCCESS_MESSAGE,
  E_KYC_SUCCESS,
  E_KYC_FAIL,
  SAVE_LIFETIME_DEPOSIT_LIMIT_SUCCESS,
  SAVE_LIFETIME_DEPOSIT_LIMIT_FAILURE,
  SAVE_AFFORDABILITY_DEPOSIT_LIMIT_SUCCESS,
  SAVE_AFFORDABILITY_DEPOSIT_LIMIT_FAILURE,
  CUSTOM_SUCCESS_NOTIFICATION,
  CUSTOM_ERROR_NOTIFICATION,
}

export const graphFullNumberCols = 8

export const graphMedNumberCols = 4

export const BACKGROUND_COLORS = [
  { stop: 0, label: '#0d0d35' },
  { stop: 0.25, label: '#202098' },
  { stop: 0.5, label: '#070797' },
  { stop: 0.75, label: '#1e1ec0' },
  { stop: 1, label: '#191957' },
]

export const COLORS = [
  { stop: 0, label: '#131b3f' },
  { stop: 0.25, label: '#419490' },
  { stop: 0.5, label: '#3bc9db' },
  { stop: 0.75, label: '#6c8660' },
  { stop: 1, label: '#8e7b40' },
]

export const COLORS_LINE_GRAPH = [
  { stop: 0, label: 'rgba(255,205,86,1)' },
  { stop: 1, label: 'rgba(255,153,0,0)' },
]

export const COLORS_DONUT_GRAPH = [
  { color: '#1E1EC0' },
  { color: '#3BC9DB' },
  { color: '#1EC0A0' },
  { color: '#3B83DB' },
]

export const QUADRANT_GRADIENT_VARIANT_1 = [
  { stop: 0, label: '#f2b524' },
  { stop: 0.2, label: '#d57117' },
  { stop: 0.5, label: '#d58000' },
  { stop: 0.75, label: '#ff0000' },
  { stop: 1, label: '#d57117' },
]

export const QUADRANT_GRADIENT_VARIANT_2 = [
  { stop: 0, label: '#f2b524' },
  { stop: 0.2, label: '#ff0000' },
  { stop: 0.5, label: '#d57117' },
  { stop: 0.25, label: '#d58000' },
  { stop: 1, label: '#f2b524' },
]

export const emailTriggerOptions = [
  { value: 'csp', name: 'Correct Score Predicted' },
  { value: 'wcp', name: 'World Cup Predictor' },
  { value: 'mr7s', name: 'Millionaires Row 7 Scores' },
  { value: 'mr', name: 'Millionaires Row' },
  { value: 'ue', name: 'Unlucky Email' },
]

export const DOCUMENT_EVENTS = [
  'load',
  'mousemove',
  'mousedown',
  'touchstart',
  'click',
  'keypress',
  'scroll',
  'wheel',
  'mousewheel',
]

// Transform from milliseconds to seconds
export const INACTIVITY_TIME = 20 * 60 * 1000

export const usersData = [
  {
    id: 0,
    name: 'John Doe',
    registered: '2018/01/01',
    role: 'Guest',
    status: 'Pending',
  },
  {
    id: 1,
    name: 'Samppa Nori',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 2,
    name: 'Estavan Lykos',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 3,
    name: 'Chetan Mohamed',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 4,
    name: 'Derick Maximinus',
    registered: '2018/03/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 5,
    name: 'Friderik Dávid',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'Active',
  },
  {
    id: 6,
    name: 'Yiorgos Avraamu',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 7,
    name: 'Avram Tarasios',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 8,
    name: 'Quintin Ed',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 9,
    name: 'Enéas Kwadwo',
    registered: '2018/03/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 10,
    name: 'Agapetus Tadeáš',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'Active',
  },
  {
    id: 11,
    name: 'Carwyn Fachtna',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 12,
    name: 'Nehemiah Tatius',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 13,
    name: 'Ebbe Gemariah',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 14,
    name: 'Eustorgios Amulius',
    registered: '2018/03/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 15,
    name: 'Leopold Gáspár',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'Active',
  },
  {
    id: 16,
    name: 'Pompeius René',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 17,
    name: 'Paĉjo Jadon',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 18,
    name: 'Micheal Mercurius',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'Inactive',
  },
  {
    id: 19,
    name: 'Ganesha Dubhghall',
    registered: '2018/03/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 20,
    name: 'Hiroto Šimun',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'Active',
  },
  {
    id: 21,
    name: 'Vishnu Serghei',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Active',
  },
  {
    id: 22,
    name: 'Zbyněk Phoibos',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'Banned',
  },
  {
    id: 23,
    name: 'Aulus Agmundr',
    registered: '2018/01/01',
    role: 'Member',
    status: 'Pending',
  },
  {
    id: 42,
    name: 'Ford Prefect',
    registered: '2001/05/25',
    role: 'Alien',
    status: "Don't panic!",
  },
]

export const DATA_LINEAR_GRADIENT = [
  {
    id: 'orangeGradient',
    stopColor1: '#FF9900',
    stopColor2: '#FFCD56',
  },
  {
    id: 'greenGradient',
    stopColor1: '#55F8B3',
    stopColor2: '#007668',
  },
]

export const fieldsLoyalty = [
  'GUID',
  'first_name',
  'last_name',
  'loyalty_points',
]

export const fieldsPlayer = [
  'GUID',
  'first_name',
  'last_name',
  'email',
  'contact_number',
  'post_code',
  'sign_up_date',
  'xtremepush_link',
]

export const ITEMS_PER_PAGE = 10
export const EMPTY_SEARCH = ''
