import GamesSelectToggle from '@components/atoms/Session/GamesSelectToggle'
import Dropdown from '@components/atoms/Dropdown'
import { BACKGROUND_COLORS, COLORS, COLORS_DONUT_GRAPH } from '@constants'

export const USER_FIELDS = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'location',
    label: 'Location',
  },
  {
    key: 'age',
    label: 'Age',
  },
  {
    key: 'gender',
    label: 'Gender',
  },
  {
    key: 'status',
    label: 'Player Status',
  },
  {
    key: 'registered',
    label: 'Registered',
  },
  {
    key: 'lastLogin',
    label: 'Last login date',
  },
  {
    key: 'prevSupport',
    label: 'Previous Support',
  },
]

export const QUICK_ACTIONS = [
  {
    label: 'Manage player wallet',
    style: 'regular',
    hasIcon: true,
    id: '',
    scope: '',
    visible: true,
    name: 'wallet',
    callback: () => {},
  },
  {
    label: 'Activate account',
    style: 'success',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'active',
    callback: () => {},
  },
  {
    label: 'KYC failed',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'kyc-fail',
    callback: () => {},
  },
  {
    label: 'International KYC failed',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'international-kyc',
    callback: () => {},
  },
  {
    label: 'Player requested to close account',
    style: 'danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'customer-closure',
    callback: () => {},
  },
  {
    label: 'Dormant account',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'dormant-account',
    callback: () => {},
  },
  {
    label: 'Suspend account',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'suspended',
    callback: () => {},
  },
  {
    label: 'Account under investigation',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'under-investigation',
    callback: () => {},
  },
  {
    label: 'Fraudster account',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'fraud',
    callback: () => {},
  },
  {
    label: 'Duplicate account',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'duplicate-account',
    callback: () => {},
  },
  {
    label: 'Lock account',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'locked',
    callback: () => {},
  },
  {
    label: 'Close account',
    style: 'danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'site-closed',
    callback: () => {},
  },
  {
    label: 'Time-Out',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'time-out',
    callback: () => {},
  },
  {
    label: 'Self-exclude player',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: 'players/self-exclusion-period',
    visible: false,
    name: 'self-excluded',
    callback: () => {},
  },
  {
    label: 'Previously excluded account',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'previously-excluded',
    callback: () => {},
  },
  {
    label: 'National register excluded account',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'national-register-excluded',
    callback: () => {},
  },
  {
    label: 'Social responsibility risk account',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'social-responsibility-risk',
    callback: () => {},
  },
  {
    label: 'Risky account',
    style: 'light-danger',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'risk',
    callback: () => {},
  },
  {
    label: 'Previous GAMSTOP account',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'previous-gamstop',
    callback: () => {},
  },
  {
    label: 'Active - Bonus Restricted account',
    style: 'warning',
    hasIcon: false,
    id: '',
    scope: '',
    visible: false,
    name: 'active-bonus-restricted',
    callback: () => {},
  },
]

export const QUICK_ACTIONS_MAP = Object.fromEntries(
  QUICK_ACTIONS?.map((item) => [item.name, item]),
)

export const GRAPH_TYPES = {
  RADAR: 'radar',
  BAR: 'bar',
}

export const GRAPH_COMPONENTS = {
  [GRAPH_TYPES.BAR]: {
    Component: Dropdown,
    props: {
      title: 'Graph values',
      selected: 'Monthly',
      onSelect: (selected) => console.log(selected),
      options: ['Monthly', 'Weekly', 'Daily'],
    },
  },
  [GRAPH_TYPES.RADAR]: {
    Component: GamesSelectToggle,
    props: {},
  },
}
export const CHANGE_PLAYER_M = 'CHANGE PLAYER ACCOUNT TO'
export const REASON = 'Required reason.'

// default dropdown options for player view dropdown
export const NAVIGATION_MENU_OPTIONS = [
  {
    optionName: 'Player Details',
    optionKey: 'PLAYER_DETAILS',
    category: 'Player Details',
    nested: [
      {
        optionName: 'Personal Details',
        optionKey: 'PLAYER_DETAILS',
        role: 'players/personal-details',
        category: 'Player Details',
      },
      {
        optionName: 'Limits',
        optionKey: 'WITHDRAWAL_DEPOSIT_LIMITS',
        role: 'players/limits',
        category: 'Player Details',
      },
    ],
  },
  {
    optionName: 'Marketing Preferences',
    optionKey: 'MARKETING',
    category: 'Marketing Preferences',
    nested: [
      {
        optionName: 'Marketing Preferences',
        optionKey: 'MARKETING',
        role: 'players/marketing-preferences',
        category: 'Marketing Preferences',
      },
    ],
  },
  {
    optionName: 'Wallets & Bonuses',
    optionKey: 'WALLETS_BONUSES',
    category: 'Wallets & Bonuses',
    nested: [
      {
        optionName: 'Wallets',
        optionKey: 'WALLETS_BONUSES',
        role: 'players/wallets',
        category: 'Wallets & Bonuses',
      },
      {
        optionName: 'Transaction History',
        optionKey: 'TRANSACTION_HISTORY',
        role: 'players/transaction-history',
        category: 'Wallets & Bonuses',
      },
      {
        optionName: 'Bonus Assignments',
        optionKey: 'BONUSES_ASSIGNMENTS',
        role: 'players/bonus-assignments',
        category: 'Wallets & Bonuses',
      },
    ],
  },
  {
    optionName: 'Player Statistics',
    optionKey: 'STATISTICS',
    category: 'Statistics',
    nested: [
      {
        optionName: 'Deposits',
        optionKey: 'STATISTICS',
        role: 'players/statistics',
        category: 'Statistics',
      },
      {
        optionName: 'Gameplay',
        optionKey: 'GRAPH',
        role: 'players/graphs',
        category: 'Statistics',
      },
    ],
  },
  {
    optionName: 'Player Notes',
    optionKey: 'PLAYER_NOTES',
    category: 'Player Notes',
    nested: [
      {
        optionName: 'Player Notes',
        optionKey: 'PLAYER_NOTES',
        role: 'players/players-notes',
        category: 'Player Notes',
      },
    ],
  },
  {
    optionName: 'Player Tags',
    optionKey: 'PLAYER_TAGS',
    category: 'Player Tags',
    nested: [
      {
        optionName: 'Player Tags',
        optionKey: 'PLAYER_TAGS',
        role: 'players/tags',
        category: 'Player Tags',
      },
    ],
  },
  {
    optionName: 'Audit History',
    optionKey: 'AUDIT_HISTORY',
    category: 'Audit History',
    nested: [
      {
        optionName: 'Audit History',
        optionKey: 'AUDIT_HISTORY',
        role: 'players/audit-history',
        category: 'Audit History',
      },
    ],
  },
  {
    optionName: 'Verification Data',
    optionKey: 'VERIFICATION_DATA',
    category: 'Verification Data',
    nested: [
      {
        optionName: 'Verification Data',
        optionKey: 'VERIFICATION_DATA',
        role: 'players/search-screen',
        category: 'Verification Data',
      },
    ],
  },
]

// wallet transactions constants
export const BALANCE_TITLE = 'Manual balance adjustment'
export const BONUS_TITLE = 'Add To Bonus'
export const BALANCE_SUBTITLE =
  'Amount (player’s balance can be increased or decreased)'
export const BONUS_SUBTITLE =
  'Bonus (player’s balance can be increased or decreased)'
export const EXCEED_BALANCE = 'Withdraw exceeds balance'
export const BONUS = 'Bonus:'
export const CASH = 'Cash:'
export const MIKADO_BANK = 'Mikado Bank'
export const CASH_BALANCE = 'cash'
export const BONUS_BALANCE = 'bonus'
export const BONUS_FORM = 'bonus'
export const CASH_FORM = 'amount'
export const ACTIVE_STATE = 'active'
export const INTL_KYC_FAIL_STATE = 'international-kyc'
export const PREVIOUS_GAMSTOP_STATE = 'previous-gamstop'
export const ACTIVE_BONUS_RESTRICTED_STATE = 'active-bonus-restricted'
export const ENABLED_STATES = [
  ACTIVE_STATE,
  INTL_KYC_FAIL_STATE,
  PREVIOUS_GAMSTOP_STATE,
  ACTIVE_BONUS_RESTRICTED_STATE,
]
export const BITCOIN_WALLET = '₿'
export const BITCOIN_PLACEHOLDER = '0.00000001'
export const COINS_PLACEHOLDER = '0.01'
export const BITCOIN_DECIMALS = 8
export const COINS_DECIMALS = 2
export const MANUAL_ADJUSTMENT_PAYMENT = 'manual'

// Live chat
export const LIVE_CHAT_HEIGHT = 106.5

// Deposit limits constants
export const DEPOSIT = 'deposit'
export const DEPOSIT_VALIDATION =
  'The daily limit must be lower than the weekly limit and the weekly limit must be lower than the monthly limit.'
export const DEPOSIT_VALIDATION_EXCEED = 'The max amount allowed is 99999.99.'
export const MAX_DEPOSIT_LIMIT = 99999.99
export const DAILY_LIMIT = 'Max Daily'
export const WEEKLY_LIMIT = 'Max Weekly'
export const MONTHLY_LIMIT = 'Max Monthly'
export const WALLET_LABEL = 'Wallet'
export const CURRENT = '*Current: '
export const DAYLY = 'daily'
export const WEEK = 'week'
export const MONTH = 'month'
export const DEPOSIT_LIMIT = 'Deposit Limits'
export const INITIAL_DEPOSITS = {
  daily: 0,
  week: 0,
  month: 0,
  id: '',
}

export const DEPOSIT_IDS = [DAYLY, WEEK, MONTH]
export const DEPOSIT_LABELS = [DAILY_LIMIT, WEEKLY_LIMIT, MONTHLY_LIMIT]
// Marketing preferences options
export const MARKETING = 'Marketing'
export const MARKETING_PREFERENCES = `${MARKETING} Preferences`
export const SMS_M = 'sms'
export const BONUS_M = 'bonus'
export const EMAIL_M = 'email'
export const MARKETING_IDS = [SMS_M, EMAIL_M, BONUS_M]

export const SMS_LABEL = `SMS ${MARKETING}`
export const EMAIL_LABEL = `Email ${MARKETING}`
export const BONUS_LABEL = 'Bonuses'
export const MARKETING_LABELS = [SMS_LABEL, EMAIL_LABEL, BONUS_LABEL]

// Withdrawal limits constants
export const WITHDRAWAL = 'withdraw'
export const WITHDRAW_LIMIT = 'Withdrawal Limits'
// Transaction history constants

export const TRANSACTION_ERROR =
  'Manual adjustment has failed. A connection error has occurred.'

export const TRANSACTION_FIELDS = [
  {
    key: 'date_time_UTC',
    label: 'Date Time UTC',
  },
  {
    key: 'reference',
    label: 'Transaction ID',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'amount',
    label: 'Cash',
  },
  {
    key: 'bonus',
    label: 'Bonus',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'balance',
    label: 'Balance',
  },
  {
    key: 'game_name',
    label: 'Game Name',
  },
  {
    key: 'game_id',
    label: 'Game ID',
  },
  {
    key: 'payment_method_name',
    label: 'Payment Method',
  },
]
export const FIELDS_BLOCKCHAIN = [
  {
    key: 'blockchain',
    label: 'Blockchain Link',
  },
]
export const CRYPTO_CURRENCIES = ['BTC']

// constants
export const URL_BLOCKCHAIN =
  'https://blockchair.com/bitcoin/transaction/bd74efbb000dc35407893185191333ffaa5e45461a4fc40f4ce2412588eb727f'

// Transaction filters constants
export const TRANSACTION_TYPE = 'Transaction Type'
export const FROM_DATE = 'From Date'
export const TO_DATE = 'To Date'
export const SEARCH = 'Search'
export const ITEMS_PER_PAGE = 'Items per page'

export const WALLET_FILTER = 'wallet'
export const TRANSACTION_FILTER = 'transaction type'
export const INIT_DATE_FILTER = 'init date'
export const END_DATE_FILTER = 'end date'
export const ITEMS_PER_PAGE_FILTER = 'Items'
export const SEARCH_FILTER = 'Search'
export const INIT_ID = 'date_init'
export const END_ID = 'date_finish'
export const TYPE_ID = 'Wallet'
export const WALLET_ID = 'Wallet'
export const ITEMS_ID = 'date_finish'
export const TRANSACTION_HISTORY_TITLE = 'Transaction History'

// Audit History constants
export const historyFields = [
  'date',
  'operator',
  'field',
  'before',
  'after',
  'reason',
  'detail',
]

export const validationDataFields = [
  'id',
  'player_id',
  'date',
  'request',
  'response',
  'type',
]

export const BONUSES_ASSIGNMENTS_FIELDS = [
  {
    key: 'campaignId',
    label: 'Campaigns',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'percentageBet',
    label: 'Percentage Bet',
  },
  {
    key: 'rolloverTarget',
    label: 'Rollover Target',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'expirationDate',
    label: 'Expiration Date',
  },
]

export const NUMBER_OF_ROWS = 30
// Graph constants
export const GRAPH_OPTIONS = [
  {
    name: 'Last 7 days',
    key: 'LAST_7',
  },
  {
    name: 'Last 14 days',
    key: 'LAST_14',
  },
  {
    name: 'Last 30 days',
    key: 'LAST_30',
  },
  {
    name: 'Last 90 days',
    key: 'LAST_90',
  },
  {
    name: 'Last 365 days',
    key: 'LAST_365',
  },
]
export const GRAPH_DAYS = [7, 14, 30, 90, 365]
export const LIMIT_GAMES = 6

// Player Details constants

// Deposit graph constants
export const DEPOSIT_LABEL = 'Total deposits: €'
export const RADART_LABEL = 'Played: '
export const BAR_GRAPH_COLORS = [
  { stop: 0.4, label: '#F2B205' },
  { stop: 0.25, label: '#F2B705' },
  { stop: 0.25, label: '#f1d11c' },
  { stop: 0, label: '#f1d17c' },
]

function getTotalDepositsLabels(label) {
  return DEPOSIT_LABEL + (Number(label)?.toFixed(2) ?? 0)
}

export const DEPOSIT_GRAPH_OPTIONS = (darkMode) => {
  const style = getComputedStyle(document.body)
  const lightModeColor = style.getPropertyValue('--color-primary')
  const darkModeColor = style.getPropertyValue('--color-secondary')
  const currentColor = darkMode ? darkModeColor : lightModeColor
  return {
    responsive: true,
    legend: {
      display: false,
      labels: {
        fontColor: '#191957',
      },
    },
    aspectRatio: 1.5,
    maintainAspectRatio: true,
    tooltips: {
      enabled: true,
      callbacks: {
        label(context) {
          const label = context?.value || ''
          return getTotalDepositsLabels(label)
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: currentColor,
            callback(value) {
              return `€${value.toFixed(2)}`
            },
          },
          gridLines: {
            color: currentColor,
            lineWidth: 1,
            zeroLineWidth: 2,
            zeroLineColor: 'red',
            drawBorder: false,
            drawTicks: false,
            borderDash: [5, 5],
            borderDashOffset: 5,
            alpha: 0.5,
            drawOnChartArea: false, // set to false to display over the bars
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: currentColor,
          },
          gridLines: {
            color: currentColor,
            lineWidth: 1,
            zeroLineWidth: 2,
            drawBorder: false,
            drawTicks: false,
            alpha: 0.5,
            offsetGridLines: false,
          },
        },
      ],
    },
  }
}

export const BAR_DATA = (totalDeposits) => [
  {
    label: 'Total deposits',
    backgroundColor: (context) => {
      const { chart } = context
      const { ctx, chartArea } = chart
      const gradient = ctx.createConicGradient(35.075, 250, 250)
      if (!chartArea) {
        return null
      }
      BAR_GRAPH_COLORS.map(({ label, stop }) =>
        gradient.addColorStop(stop, label),
      )

      return gradient
    },
    barThickness: 6,
    borderColor: 'none',
    borderWidth: 2,
    hoverBorderColor: 'none',
    data: totalDeposits,
  },
]

export const DEPOSIT_GRAPH_SELECTIONS = [
  {
    name: 'Last 12 months',
    key: 12,
  },
  {
    name: 'Last 6 months',
    key: 6,
  },
  {
    name: 'Last 7 days',
    key: 7,
  },
]

export const MONTH_DEPOSIT_TYPE = 'month'
export const DAY_DEPOSIT_TYPE = 'day'
export const GRAPH_STYLES_SIZES = (isSmScreen) =>
  !isSmScreen
    ? { height: 500, width: 500, margin: 'auto' }
    : { height: 350, width: 350, margin: 'auto' }

// Radar chart

export const dataSet = (values, valuesRef) => [
  {
    label: 'Played',
    fill: true,
    data: values,
    backgroundColor: (context) => {
      const { ctx } = context.chart
      const gradient = ctx.createConicGradient(35.075, 250, 250)

      COLORS.map(({ label, stop }) => gradient.addColorStop(stop, label))

      return gradient
    },
    pointBorderColor: 'yellow',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(179,181,198,1)',
    borderColor: valuesRef[0] === 10 ? 'none' : 'rgba(0, 0, 0, 0.1)',
  },
  {
    label: '',
    fill: true,
    data: valuesRef,
    backgroundColor: (context) => {
      const { ctx } = context.chart
      const gradient = ctx.createConicGradient(35.075, 250, 250)

      BACKGROUND_COLORS.map(({ label, stop }) =>
        gradient.addColorStop(stop, label),
      )
      return gradient
    },
    pointBorderColor: 'yellow',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(179,181,198,1)',
    borderColor: valuesRef[0] === 10 ? 'none' : 'rgba(0, 0, 0, 0.1)',
  },
]

function formatLabelsForGraphs(context, datasetIndex) {
  const gameName = datasetIndex?.labels?.[context?.index]?.[0]
  const label = context?.label ?? ''
  const title = `${gameName} ${Number(label)?.toFixed(2)}`
  return context?.datasetIndex === 0 ? `${RADART_LABEL + title}%` : ''
}

export const options = {
  responsive: true,
  scale: {
    ticks: {
      display: false,
    },
    pointLabels: {
      fontColor: '#fff',
    },
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
    },
  },
  legend: {
    display: false,
  },
  aspectRatio: 1,
  tooltips: {
    callbacks: {
      label(context, datasetIndex) {
        return formatLabelsForGraphs(context, datasetIndex)
      },
      title() {
        return ''
      },
      afterLabel() {
        return ''
      },
      value() {
        return ''
      },
      formatter() {
        return ''
      },
    },
    enabled: true,
  },
}

export const dataSetDonut = (values) => [
  {
    data: values,
    borderWidth: [2, 2, 2, 2],
    backgroundColor(context) {
      const { ctx } = context.chart
      const gradient = ctx.createLinearGradient(0, 0, context.chart.width, 0)
      values.forEach((value, index) => {
        gradient.addColorStop(value / 100, COLORS_DONUT_GRAPH[index].color)
      })

      return gradient
    },
    pointBorderColor: 'yellow',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(179,181,198,1)',
    borderColor: 'none',
    fontColor: '#fff',
    plugins: {
      legend: {
        labels: {
          fontColor: '#fff',
        },
      },
    },
  },
]

function getDonutLabel(context, datasetIndex) {
  const label =
    `${datasetIndex?.labels?.[context?.index]?.[0]} ${
      datasetIndex?.datasets[0]?.data?.[context?.index]
    }%` || ''
  return label
}

export const optionsDonut = {
  plugins: {
    legend: {
      labels: {
        fontColor: '#fff',
      },
    },
  },
  aspectRatio: 1,
  responsive: true,
  tooltips: {
    callbacks: {
      label(context, datasetIndex) {
        return getDonutLabel(context, datasetIndex)
      },
      title() {
        return ''
      },
    },
    enabled: true,
  },
}

// Notes constants
// Notes actions
export const NOTE_CLOSE = 'close'
export const NOTE_EDIT = 'edit'
export const NOTE_DELETE = 'deelte'
export const NOTE_EXPAND = 'expand'
export const NOTE_CONTRACT = 'contract'
export const NOTE_NONE = 'none'
export const NOTE_SAVE = 'save'
export const NOTE_ADD = 'add'
export const NOTE_COLLAPSE_ALL = 'contract_all'
export const NOTE_EXPAND_ALL = 'expand_all'
export const COLLAPSE = 'Collapse all'
export const EXPAND = 'Expand all'
export const ADD = 'Add note'
export const LAST_EDIT = 'Last Edit: '
export const USER = 'User: '
export const NOTES = 'notes'
export const TITLE = 'title'
export const TEXT_AREA_HEIGHT = 150
export const NOTE_EMPTY = {
  title: '',
  id: 0,
  notes: '',
  lastEdit: new Date().toISOString(),
  isCollapsed: true,
  isEditing: false,
  toDelete: false,
}

// Icons width
export const ICON_WIDTH = 20
export const ICON_MAINTENANCE_WIDTH = 100
export const ICON_HEADER_WIDTH = 100
export const NOTES_ITEMS_PER_PAGE = 10
export const NOTES_MAXIMUM_CONTENT = 5000
export const DELETE_NOTE_TITLE = 'Do you want to delete this note?'

// Self exclusion constant messages
export const S1_M = 'duration'
export const S1_LABEL = 'Self-exclude by duration'
export const T1_LABEL = 'Time-out by duration'

export const DURATION_OPTIONS = [
  { value: 180, label: '6 months' },
  { value: 365, label: '1 year' },
  { value: 730, label: '2 years' },
  { value: 1095, label: '3 years' },
  { value: 1460, label: '4 years' },
  { value: 1825, label: '5 years' },
]

export const TIME_OUT_DURATION_OPTIONS = [
  { value: 1, label: '24 hours' },
  { value: 2, label: '48 hours' },
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days' },
  { value: 120, label: '120 days' },
  { value: 150, label: '150 days' },
  { value: 180, label: '180 days' },
]

export const TIME_LABEL = 'Time'
export const SELF_EXCLUDED_TITLE = 'CHANGE PLAYER SELF-EXCLUDED DURATION'
export const NO_SELF_EXCLUDED_TITLE =
  'CHANGE PLAYER PLAYER ACCOUNT TO SELF-EXCLUDED'

export const TIME_OUT_TITLE = 'CHANGE PLAYER TIME-OUT DURATION'
export const NO_TIME_OUT_TITLE = 'CHANGE PLAYER PLAYER ACCOUNT TO TIME-OUT'

export const SELF_EXCLUDED = 'self-excluded'
export const THRESHOLD_RESTRICTED = 'threshold-restriction'
export const TIME_OUT = 'time-out'
export const UTC_LABEL = 'UTC'

// Financial constants
export const DEFAULT_WALLET = 'GBP'

// Export reports constants

// Keys to delete
export const TO_FORMAT_TOP_PLAYERS_WAGERS = {
  name: 'Name',
  last_login: 'Last Login',
  total_wagers: 'Total Wagers',
  xtremepush_url: 'xtremepush Link',
}
export const TO_FORMAT_TOP_PLAYERS_SESSION = {
  name: 'Name',
  last_login: 'Last Login',
  session_duration: 'Session Duration',
  xtreme_push_url: 'xtremepush Link',
}
export const TO_FORMAT_FINANCIAL = {
  player_id: 'GUID',
  transaction_date: 'Date Time UTC',
  id: 'Reference',
  type: 'Type',
  amount: 'Cash',
  bonus: 'Bonus',
  status: 'Status',
  balance: 'Balance',
}

export const TO_FORMAT_PLAYERS = {
  GUID: 'GUID',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  sign_up_date: 'Sign Up Date',
  xtremepush_url: 'Xtremepush Url',
}

// Titles files
export const TOP_PLAYERS_WAGERS = 'Top Players Wagers'
export const TOP_PLAYERS_SESSION = 'Top Players Sessions'
export const FINANCIAL = 'Financial'
export const PLAYERS = 'Players'
export const EXPORT_TITLE = 'Export to CSV'

// Engagement constants
export const SELECT_OPTIONS = ['Show', 'for the last']

export const PLAYER_TEST_ID_PROD = 'ab5f4d7d-fec5-11ec-b3c5-0242c0a8100c'
export const PLAYER_TEST_ID_DEV = '03e42143-1234-11ef-99b1-42e9842e4ca3'
