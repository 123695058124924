export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST'
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR'

export const LOGOUT = 'LOGOUT'

export const FETCH_REFRESH_TOKEN_REQUEST = 'FETCH_REFRESH_TOKEN_REQUEST'
export const FETCH_REFRESH_TOKEN_SUCCESS = 'FETCH_REFRESH_TOKEN_SUCCESS'

export const SET_FIRST_LOGIN_TO_FALSE = 'SET_FIRST_LOGIN_TO_FALSE'

export const SET_CURRENT_PLAYER_REQUEST = 'SET_CURRENT_PLAYER_REQUEST'
export const SET_CURRENT_PLAYER_SUCCESS = 'SET_CURRENT_PLAYER_SUCCESS'
export const SET_CURRENT_PLAYER_ERROR = 'SET_CURRENT_PLAYER_ERROR'
export const SET_CURRENT_PLAYER_OPTION = 'SET_CURRENT_PLAYER_OPTION'

export const UPDATE_LIMITS_REQUEST = 'UPDATE_LIMITS_REQUEST'
export const UPDATE_LIMITS_SUCCESS = 'UPDATE_LIMITS_SUCCESS'
export const UPDATE_LIMITS_ERROR = 'UPDATE_LIMITS_ERROR'

export const UPDATE_CURRENT_DEPOSIT_SUCCESS = 'UPDATE_CURRENT_DEPOSIT_SUCCESS'
export const UPDATE_CURRENT_DEPOSIT_REQUEST = 'UPDATE_CURRENT_DEPOSIT_REQUEST'
export const UPDATE_CURRENT_DEPOSIT_ERROR = 'UPDATE_CURRENT_DEPOSIT_ERROR'
export const UPDATE_CURRENT_WITHDRAW_SUCCESS = 'UPDATE_CURRENT_WITHDRAW_SUCCESS'
export const UPDATE_CURRENT_WITHDRAW_REQUEST = 'UPDATE_CURRENT_WITHDRAW_REQUEST'
export const UPDATE_CURRENT_WITHDRAW_ERROR = 'UPDATE_CURRENT_WITHDRAW_ERROR'

export const UPDATE_BALANCE_REQUEST = 'UPDATE_BALANCE_REQUEST'
export const UPDATE_BALANCE_SUCCESS = 'UPDATE_BALANCE_SUCCESS'
export const UPDATE_BALANCE_ERROR = 'UPDATE_BALANCE_ERROR'

export const SET_CURRENT_STATUS_ERROR = 'SET_CURRENT_STATUS_REQUEST'
export const SET_CURRENT_STATUS_SUCCESS = 'SET_CURRENT_STATUS_SUCCESS'
export const SET_CURRENT_STATUS_REQUEST = 'SET_CURRENT_STATUS_ERROR'

export const SET_CURRENT_QUERY_ERROR = 'SET_CURRENT_QUERY_REQUEST'
export const SET_CURRENT_QUERY_SUCCESS = 'SET_CURRENT_QUERY_SUCCESS'
export const SET_CURRENT_QUERY_REQUEST = 'SET_CURRENT_QUERY_ERROR'

export const SET_BULK_TRANSACTIONS = 'SET_BULK_TRANSACTIONS'
export const SET_BULK_TRANSACTIONS_STATUS = 'SET_BULK_TRANSACTIONS_STATUS'
export const RESET_BULK_TRANSACTIONS = 'RESET_BULK_TRANSACTIONS'
